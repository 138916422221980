import React, {createContext} from "react";
import {useLocalStorage} from "../servicios/useLocalStorage";
import {dbDexie} from "../servicios/IndexedDb-dexie";
import { useLiveQuery } from "dexie-react-hooks";

export const ERPMasterContext = createContext();

export const ERPMasterProvider = ({children}) => {
    
    const [id_usuario, setid_usuario] = useLocalStorage("usr", null);
    const [id_compania, setid_compania] = useLocalStorage("comp", null);
    const [despacho, setdespacho] = useLocalStorage("depacho", false);
    const [token, setToken] = useLocalStorage("token", null);
    const lista_monedas = useLiveQuery(() => dbDexie.monedas.toArray());
    const _id_moneda_base = useLiveQuery(() => dbDexie.moneda_base.toArray());
    const id_moneda_base = !_id_moneda_base || !_id_moneda_base[0] ? 0 : _id_moneda_base[0].id;

    return (
        <ERPMasterContext.Provider
            value={{id_compania, setid_compania, id_usuario, setid_usuario, token, setToken, lista_monedas, id_moneda_base : id_moneda_base, despacho, setdespacho}}
        >
            {children}
        </ERPMasterContext.Provider>
    );
};
