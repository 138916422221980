import React from "react";
import {
    Form,
    FormItem,
    Input,
    CheckBox,
    FormGroup,
    Select,
    InputType,
    Option,
    TextArea,
    DateTimePicker,
    Toolbar,
    ToolbarSpacer,
    Button,
    Title,
    TitleLevel,
    ObjectPage,
    ObjectPageSection,
    DynamicPageHeader,
    Loader,
} from "@ui5/webcomponents-react";

const Contacto = () => {
    return (
        <>
            <ObjectPage alwaysShowContentHeader={true} headerContent={null}>
                <ObjectPageSection titleText="General" id="general" aria-label="Goals">
                    <Form columnsXL={3} columnsL={3} columnsM={2} labelSpanM={12}>
                        <FormGroup>
                            <FormItem label={"Código"}>
                                <Input type={InputType.Text} />
                            </FormItem>

                            <FormItem label={"Nombre"}>
                                <Input type={InputType.Text} />
                            </FormItem>

                            <FormItem label={"Nombre de contacto"}>
                                <Input type={InputType.Text} />
                            </FormItem>

                            <FormItem label={"Identidad"}>
                                <Input />
                            </FormItem>

                            <FormItem label={"Fecha de nacimiento"}>
                                <DateTimePicker
                                    id="fecha_movimiento"
                                    formatPattern="dd/MM/yyyy HH:mm"
                                />
                            </FormItem>

                            <FormItem label="Dirección">
                                <TextArea
                                    id="direccion"
                                    rows={5}
                                    style={{
                                        width: "210px",
                                    }}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText={"Opciones de contacto"}>
                            <FormItem label="Es cliente">
                                <CheckBox />
                            </FormItem>
                            <FormItem label="Es proveedor">
                                <CheckBox />
                            </FormItem>
                            <FormItem label="Habilitado">
                                <CheckBox />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText={"Fiscal Costa Rica"}></FormGroup>
                    </Form>
                </ObjectPageSection>

                <ObjectPageSection id="cliente" titleText="Cliente">
                    <Form columnsXL={3} columnsL={3} columnsM={2} labelSpanM={12}>
                        <FormGroup titleText="General">
                            <FormItem label={"Última venta"}>
                                <Input type={InputType.Text} readonly />
                            </FormItem>

                            <FormItem label={"Es cliente corporativo"}>
                                <Input type={InputType.Text} readonly />
                            </FormItem>

                            <FormItem label="Tafica aplica">
                                <Input />
                            </FormItem>

                            <FormItem label="Vendedor">
                                <Input />
                            </FormItem>

                            <FormItem label="Zona">
                                <Input />
                            </FormItem>

                            <FormItem label="Cliente corporativo">
                                <Input />
                            </FormItem>

                            <FormItem label="Lista de precio">
                                <Input />
                            </FormItem>

                            <FormItem label="Moneda de venta predeterminada">
                                <Input />
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText={"Crédito"}>
                            <FormItem label="Límite de cédito">
                                <Input />
                            </FormItem>
                            <FormItem label="Saldo pendiente">
                                <Input />
                            </FormItem>
                            <FormItem label="Tipo de crédito">
                                <Input />
                            </FormItem>
                            <FormItem label="Máximo CXC vencidas">
                                <Input />
                            </FormItem>
                            <FormItem label="Periodo de gracia para CXC vencidas">
                                <Input />
                            </FormItem>
                            <FormItem label="Cobra intereses moratorios?">
                                <Input />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText={"Descuentos"}>
                            <FormItem label="% Descuento máximo para ventas de contado">
                                <Input />
                            </FormItem>
                            <FormItem label="% Descuento máximo para ventas de crédito">
                                <Input />
                            </FormItem>
                            <FormItem label="Aplica automáticamente">
                                <Input />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText={"Comisión de ventas"}>
                            <FormItem label="% de contado">
                                <Input />
                            </FormItem>
                            <FormItem label="% de crédito">
                                <Input />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText={"Contabilidad"}>
                            <FormItem label="Cuenta contable (Cuentas por cobrar)">
                                <Input />
                            </FormItem>
                            <FormItem label="Cuenta contable (Otras cuentas por cobrar)">
                                <Input />
                            </FormItem>
                            <FormItem label="Ventas de servicios exentos">
                                <Input />
                            </FormItem>
                            <FormItem label="Ventas de servicios gravados">
                                <Input />
                            </FormItem>
                            <FormItem label="Ventas de artículos exentos">
                                <Input />
                            </FormItem>
                            <FormItem label="Ventas de artículos gravados">
                                <Input />
                            </FormItem>
                        </FormGroup>
                    </Form>
                </ObjectPageSection>

                <ObjectPageSection id="proveedor" titleText="Proveedor"></ObjectPageSection>

                <ObjectPageSection id="otros" titleText="Otros"></ObjectPageSection>
            </ObjectPage>
        </>
    );
};

export default Contacto;
