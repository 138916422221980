import React, {useContext, useState, useEffect, useRef} from "react";
import {
    Form,
    FormItem,
    Input,
    FormGroup,
    Select,
    InputType,
    Option,
    TextArea,
    DateTimePicker,
    Toolbar,
    ToolbarSpacer,
    Button,
    Title,
    TitleLevel,
    ObjectPage,
    ObjectPageSection,
    DynamicPageHeader,
    Loader,
} from "@ui5/webcomponents-react";
import SeleccionCliente from "../general/SeleccionCliente";
import {GeneralContext} from "../contexts/GeneralContext";
import GridDetalleContable from "../contabilidad/GridDetalleContable";
import Boton from "../components/Boton";
import {get} from "../servicios/api-m";
import {Funciones} from "../servicios/utilidades";
import {ERPMasterContext} from "../contexts/ERPMasterAuth";
import {useSearchParams, useParams} from "react-router-dom";
import NumberFormat from "react-number-format";
import InputMonto from "../components/InputMonto";
import InputLink from "../components/InputLink";
import moment from "moment";
import {useLocalStorage} from "../servicios/useLocalStorage";
import ModalContainer from "../components/Modal";
import Contacto from "../mantenimiento/Contacto";

const CuentaXCobrar = () => {
    const [searchParams] = useSearchParams();
    const {id_cxc} = useParams();
    const [model, setModel] = useLocalStorage("cxc-" + searchParams.get("sesion"), {});
    const [actualizaLocalStore, setactualizaLocalStore] = useState(false);

    useEffect(() => {        
        if (actualizaLocalStore) {
            setModel({
                id: null,
                documento: "",
                referencia: form_referencia,
                id_departamento: form_id_departamento,
                id_tipo_credito: form_id_tipo_credito,
                fecha_movimiento: form_fecha_movimiento,
                fecha_vencimiento: form_fecha_vencimiento,
                id_cobrador: form_id_cobrador,
                comentario: form_comentario,
                monto: form_monto,
                saldo: 0,
                id_moneda: form_id_moneda,
                tc: form_tc,
                tc_base: form_tc_base,
                cliente: form_cliente,
                detalle_contable: form_detalle_contable,
            });
            setactualizaLocalStore(false);
        }
    }, [actualizaLocalStore]);

    const estadoInicial = {
        id: null,
        documento: "",
        referencia: "new",
        id_departamento: 0,
        id_tipo_credito: null,
        fecha_movimiento: null,
        fecha_vencimiento: null,
        id_cobrador: null,
        comentario: null,
        monto: 0,
        saldo: 0,
        id_moneda: null,
        tc: 0,
        tc_base: 0,
        cliente: {},
        detalle_contable: [],
    };

    const UpdateForm = (Funchook, id, val, actualizaModel = true) => {
        if (Funchook) Funchook(val);
        else setModel({...model, [id]: val});
        if (actualizaModel) setactualizaLocalStore(true);
    };

    //#region Eventos

    //#region  STATE FORMS
    const [formDataCargada, setformDataCargada] = useState(false);
    const [modalContactoVisible, setmodalContactoVisible] = useState(false)

    const [form_id, setform_id] = useState(null);
    const [form_documento, setform_documento] = useState("");
    const [form_referencia, setform_referencia] = useState("");
    const [form_id_departamento, setform_id_departamento] = useState(0);
    const [form_id_tipo_credito, setform_id_tipo_credito] = useState(null);
    const [form_fecha_movimiento, setform_fecha_movimiento] = useState(null);
    const [form_fecha_vencimiento, setform_fecha_vencimiento] = useState(null);
    const [form_id_cobrador, setform_id_cobrador] = useState(null);
    const [form_comentario, setform_comentario] = useState("");
    const [form_monto, setform_monto] = useState(0);
    const [form_saldo, setform_saldo] = useState(0);
    const [form_id_moneda, setform_id_moneda] = useState(null);
    const [form_simbolo_moneda, setform_simbolo_moneda] = useState("");
    const [form_tc, setform_tc] = useState(0);
    const [form_tc_base, setform_tc_base] = useState("");
    const [form_cliente, setform_cliente] = useState({});
    const [form_detalle_contable, setform_detalle_contable] = useState([]);
    //data
    const [listaTiposCredito, setListaTiposCredito] = useState([]);
    const [listaDepartamentos, setListaDepartamentos] = useState([]);
    const [listaVendedores, setListaVendedores] = useState([]);
    //#endregion

    const [ViewProcesando, setViewProcesando] = useState(false)
    const {CerrarTarea} = useContext(GeneralContext);
    const {lista_monedas, id_moneda_base} = useContext(ERPMasterContext);
    const [estadoDocumento, setestadoDocumento] = useState({
        respuesta: false,
        procesando: false,
        cargando: false,
        mensaje: "",
        editar: false,
        ver: false,
        sesion: searchParams.get("sesion"),
        id: null,
        codigo_dpto_defecto: "",
    });

    const GetConsecutivo = async () => {
        if (!form_id_departamento) return;
        setestadoDocumento({...estadoDocumento, cargando: true});
        try {
            let res = await get(`/api/CXC/GetConsecutivoCXC?departamento=${form_id_departamento}`);
            setform_documento(`CU-${Funciones.RellenaCeros(res, 7)}`);
        } catch (error) {}
        setestadoDocumento({...estadoDocumento, cargando: false});
    };

    const GetPreviaDetalleContable = async () => {
        if (!formDataCargada) return;

        setestadoDocumento({...estadoDocumento, cargando: true});
        try {
            let res = await get(
                `/api/CXC/GetPreviaContableCXC?id_dpto=${form_id_departamento}&id_cnt=${form_cliente.id}&id_mnd=${form_id_moneda}&total=${form_monto}&tc=${form_tc}&tcbase=${form_tc_base}`
            );
            setform_detalle_contable(res);
        } catch (error) {}
        setestadoDocumento({...estadoDocumento, cargando: false});
    };

    const OnDetalleContableActualiza = (res) => {
        console.log("Detalle Contable Actualizado => ", res);
        UpdateForm(null, "detalle_contable", res, false);
    };

    const OnClienteSelecciona = (res) => {
        if (res.respuesta && res.data) {
            UpdateForm(setform_cliente, "cliente", {
                id: res.data.id,
                nombre: res.data.nombre,
                identidad: res.data.identidad,
                telefono1: res.data.telefono1,
            });
        }
    };

    const cargaDocumento = async () => {
        let data = await get("/api/CXC/cxcIni");

        setestadoDocumento({...estadoDocumento, codigo_dpto_defecto: data.codigo_dpto_defecto});
        setListaTiposCredito(data.lista_tipos_credito);
        setListaDepartamentos(data.lista_departamentos);
        setListaVendedores(data.lista_vendedores);

        if (id_cxc > 0) {
            setform_referencia("edit");
        } else {
            let dpto = data.lista_departamentos.filter((r) => r.codigo == data.codigo_dpto_defecto);

            if (dpto && dpto.length > 0) setform_id_departamento(dpto[0].id);

            console.warn("MODEL CREAR => ", model);

            //asigna valores de modelo
            setform_fecha_vencimiento(model.fecha_vencimiento ?? null);
            setform_referencia(model.referencia ?? "");
            setform_id_departamento(model.id_departamento ?? null);
            setform_fecha_movimiento(model.fecha_movimiento ?? null);
            setform_id_tipo_credito(model.id_tipo_credito ?? null);
            setform_id_cobrador(model.id_cobrador ?? null);
            setform_comentario(model.comentario ?? "");
            setform_cliente(model.cliente ?? {});
            setform_id_moneda(model.id_moneda ?? null);
            setform_monto(model.monto ?? 0);
            setform_tc(model.tc ?? 0);
            setform_tc_base(model.tc_base ?? 0);
            setform_detalle_contable(model.detalle_contable ?? []);

            setformDataCargada(true);
        }
    };

    const OnCancelar = () => {
        CerrarTarea("cxc-nueva", estadoDocumento.sesion);
    };

    const OnProcesar = () => {
        if (ViewProcesando) return;
        setViewProcesando(true);

        
        console.log("procesando....");

        setTimeout(() => {
            setViewProcesando(false);
        }, 5000);
    };

    //#region useEffect
    useEffect(() => {
        GetConsecutivo();
    }, [form_id_departamento]);

    useEffect(() => {
        GetPreviaDetalleContable();
    }, [form_id_departamento, form_monto, form_tc]);

    useEffect(() => {
        if (!lista_monedas || lista_monedas.length == 0 || !form_id_moneda) return;

        UpdateForm(
            setform_tc,
            "tc",
            lista_monedas.filter((r) => r.id == form_id_moneda)[0].tipo_cambio_venta,
            false
        );

        UpdateForm(
            setform_simbolo_moneda,
            "simbolo_moneda",
            lista_monedas.filter((r) => r.id == form_id_moneda)[0].simbolo,
            false
        );

        UpdateForm(
            setform_tc_base,
            "tc_base",
            lista_monedas.filter((r) => r.id == id_moneda_base)[0].tipo_cambio_venta
        );
    }, [form_id_moneda]);

    useEffect(() => {
        if (form_id_tipo_credito) {
            let diasCredito = listaTiposCredito.filter((r) => r.id == form_id_tipo_credito)[0]
                .diasCredito;
            var new_date = moment(form_fecha_movimiento, "DD/MM/YYYY hh:mm").add(
                "days",
                diasCredito
            );
            UpdateForm(
                setform_fecha_vencimiento,
                "fecha_vencimiento",
                moment(new_date).format("DD/MM/YYYY HH:mm"),
                false
            );
            UpdateForm(setform_id_tipo_credito, "id_tipo_credito", form_id_tipo_credito);
        }
    }, [form_fecha_movimiento, form_id_tipo_credito]);

    useEffect(() => {
        console.log("ID CXC =>", id_cxc);
        console.log("ID MONEDA BASE =>", id_moneda_base);
        cargaDocumento();
    }, []);

    //#endregion

    //#endregion

    return (
        <>
            {estadoDocumento.cargando && <Loader />}
            <ModalContainer stateVisible={modalContactoVisible} fStateVisible={setmodalContactoVisible}>
                <Contacto />
            </ModalContainer>
            <ObjectPage
                alwaysShowContentHeader={true}
                headerContent={
                    <DynamicPageHeader
                        style={{width: "110%"}}
                        headerPinned={true}
                        children={
                            <Toolbar>
                                <Title level={TitleLevel.H3}>Nueva cuenta por cobrar</Title>
                                <ToolbarSpacer />
                                <Button icon="decline" design="" onClick={OnCancelar}>
                                    Cancelar
                                </Button>
                                <Boton
                                    icon="accept"
                                    design="Emphasized"
                                    ocupado={ViewProcesando}
                                    onClick={OnProcesar}
                                >
                                    Procesar
                                </Boton>
                            </Toolbar>
                        }
                    ></DynamicPageHeader>
                }
            >
                <ObjectPageSection titleText="General" id="general" aria-label="Goals">
                    <Form columnsXL={3} columnsL={3} columnsM={2} labelSpanM={12}>
                        <FormGroup titleText="Parámetros">
                            <FormItem label={"Departamento"}>
                                <Select
                                    id="id_departamento"
                                    onChange={(e) =>
                                        UpdateForm(
                                            setform_id_departamento,
                                            "id_departamento",
                                            e.detail.selectedOption.value
                                        )
                                    }
                                >
                                    {listaDepartamentos.map((rw) => (
                                        <Option
                                            selected={rw.id == form_id_departamento}
                                            value={rw.id}
                                        >
                                            {rw.text}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>

                            <FormItem label={"Documento"}>
                                <Input type={InputType.Text} value={form_documento} readonly />
                            </FormItem>

                            <FormItem label={"Referencia"}>
                                <Input
                                    id="referencia"
                                    onBlur={(e) =>
                                        UpdateForm(setform_referencia, "referencia", e.target.value)
                                    }
                                    type={InputType.Text}
                                    value={form_referencia}
                                />
                            </FormItem>

                            <FormItem label={"Fecha de movimiento"}>
                                <DateTimePicker
                                    id="fecha_movimiento"
                                    formatPattern="dd/MM/yyyy HH:mm"
                                    onChange={(e) =>
                                        UpdateForm(
                                            setform_fecha_movimiento,
                                            "fecha_movimiento",
                                            e.target.value
                                        )
                                    }
                                    value={form_fecha_movimiento}
                                />
                            </FormItem>

                            <FormItem label={"Tipo de crédito"}>
                                <Select
                                    id="id_tipo_credito"
                                    onChange={(e) =>
                                        UpdateForm(
                                            setform_id_tipo_credito,
                                            "id_tipo_credito",
                                            e.detail.selectedOption.value
                                        )
                                    }
                                >
                                    <Option></Option>
                                    {listaTiposCredito.map((rw) => (
                                        <Option
                                            selected={rw.id == form_id_tipo_credito}
                                            value={rw.id}
                                        >
                                            {rw.descripcion}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>

                            <FormItem label={"Fecha de vencimiento"}>
                                <DateTimePicker
                                    id="fecha_vencimiento"
                                    formatPattern="dd/MM/yyyy HH:mm"
                                    value={form_fecha_vencimiento}
                                    readonly
                                />
                            </FormItem>

                            <FormItem label={"Cobrador"}>
                                <Select
                                    id="id_cobrador"
                                    onChange={(e) =>
                                        UpdateForm(
                                            setform_id_cobrador,
                                            "id_cobrador",
                                            e.detail.selectedOption.value
                                        )
                                    }
                                >
                                    {listaVendedores.map((rw) => (
                                        <Option selected={rw.id == form_id_cobrador} value={rw.id}>
                                            {rw.text}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>

                            <FormItem label="Comentario">
                                <TextArea
                                    id="comentario"
                                    rows={5}
                                    value={form_comentario}
                                    onChange={(e) =>
                                        UpdateForm(setform_comentario, "comentario", e.target.value)
                                    }
                                    style={{
                                        width: "210px",
                                    }}
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText={"Cliente"}>
                            <FormItem>
                                <SeleccionCliente OnClienteSeleccion={OnClienteSelecciona} />
                            </FormItem>

                            <FormItem label={"Nombre"}>
                                <InputLink
                                    onLinkClick={() => setmodalContactoVisible(true)}
                                    value={form_cliente?.nombre}
                                    type={InputType.Text}
                                    readonly
                                />
                            </FormItem>

                            <FormItem label={"Identidad"}>
                                <Input
                                    value={form_cliente?.identidad}
                                    type={InputType.Text}
                                    readonly
                                />
                            </FormItem>

                            <FormItem label={"Teléfono 1"}>
                                <Input
                                    value={form_cliente?.telefono1}
                                    type={InputType.Text}
                                    readonly
                                />
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText={"Valores"}>
                            <FormItem label={"Moneda"}>
                                <Select
                                    id="id_moneda"
                                    onChange={(e) =>
                                        UpdateForm(
                                            setform_id_moneda,
                                            "id_moneda",
                                            e.detail.selectedOption.value
                                        )
                                    }
                                >
                                    <Option></Option>
                                    {lista_monedas &&
                                        lista_monedas.map((rw) => (
                                            <Option
                                                selected={rw.id == form_id_moneda}
                                                value={rw.id}
                                            >
                                                {rw.codigo} {rw.moneda}
                                            </Option>
                                        ))}
                                </Select>
                            </FormItem>

                            <FormItem label={"Tipo cambio"}>
                                <Input
                                    id="tc"
                                    value={form_tc}
                                    type={InputType.Number}
                                    onChange={(e) => UpdateForm(setform_tc, "tc", e.target.value)}
                                />
                            </FormItem>
                            <FormItem label={"Tipo cambio base"}>
                                <Input
                                    id="tc_base"
                                    value={form_tc_base}
                                    type={InputType.Number}
                                    onChange={(e) =>
                                        UpdateForm(setform_tc_base, "tc_base", e.target.value)
                                    }
                                />
                            </FormItem>

                            <FormItem label={"Monto"}>
                                <InputMonto
                                    simbolo={form_simbolo_moneda}
                                    value={form_monto}
                                    onChange={(val) => UpdateForm(setform_monto, "monto", val)}
                                />
                            </FormItem>
                            <FormItem label={"Saldo"}>
                                <Input
                                    id="saldo"
                                    value={form_saldo}
                                    type={InputType.Number}
                                    readonly
                                />
                            </FormItem>
                        </FormGroup>
                    </Form>
                </ObjectPageSection>
                <ObjectPageSection
                    titleText="Detalle contable"
                    id="detalle-contable"
                    aria-label="Goals"
                >
                    <GridDetalleContable
                        data={form_detalle_contable}
                        OnActualizaData={OnDetalleContableActualiza}
                    />
                </ObjectPageSection>
            </ObjectPage>
        </>
    );
};

export default CuentaXCobrar;
