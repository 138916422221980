import React from "react";
import {
  ProductSwitch,
  ProductSwitchItem,  
} from "@ui5/webcomponents-react";
import { useNavigate } from "react-router-dom";

const IndexVenta = () => {

  const navigate = useNavigate();

  const HClick = (m) => {

  }

  return (
    <ProductSwitch>

      <ProductSwitchItem
        titleText="Emitidas"
        subtitleText="Listado de ventas emitidas"
        icon="contacts"
        onClick={() => navigate("/venta/emitidas")}
      />

      <ProductSwitchItem
        titleText="Nueva"
        subtitleText="Nueva factura"
        icon="business-objects-experience"
        onClick={() => navigate("/venta/nueva")}
      />

      <ProductSwitchItem
        titleText="Reportes"
        subtitleText="Reportes de ventas"
        icon="business-objects-experience"
        onClick={() => navigate("/venta/reportes")}
      />
    </ProductSwitch>
  );
};

export default IndexVenta;
