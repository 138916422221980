import React, {useState, useEffect} from "react";
import {Table, TableColumn, Label, TableRow, TableCell, Button} from "@ui5/webcomponents-react";
import NumberFormat from "react-number-format";
import {get} from "../servicios/api-m";
import {Input, AutoComplete, Form} from "antd";
const {Search} = Input;

const GridDetalleContable = ({data, OnActualizaData}) => {
    /* ESTRUCTURA */
    // { id, codigo, cuenta, debe, haber, editable }

    const [totalDebe, settotalDebe] = useState(0);
    const [totalHaber, settotalHaber] = useState(0);

    const searchResult = (resultado) =>
        setOptions(
            resultado.map((rw, idx) => {
                return {
                    value: rw.codigo,
                    label: (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span>{rw.text}</span>
                        </div>
                    ),
                };
            })
        );

    const [options, setOptions] = useState([]);
    const [resultCuentasContables, setresultCuentasContables] = useState([]);

    const [detalle, setDetalle] = useState([]);

    const OnNuevaLinea = () => {
        setDetalle((prevSelected) => [
            ...prevSelected,
            {id: 0, codigo: "", cuenta: "", debe: 0, haber: 0, editable: true},
        ]);
    };

    useEffect(() => {
        setDetalle(data);
    }, [data]);

    useEffect(() => {
        if (!detalle) return;
        let debe = 0;
        let haber = 0;
        detalle.forEach((rw) => {
            debe += Number(rw.debe);
            haber += Number(rw.haber);
        });
        settotalDebe(debe);
        settotalHaber(haber);
        OnActualizaData(detalle);
    }, [detalle]);

    const handleSearch = async (value) => {
        try {
            let resultado = await get(`/api/General/AutoCompleteCuentaContable?b=${value}`);
            searchResult(resultado);
            setresultCuentasContables(resultado);
        } catch (error) {
            console.error(error);
        }
    };

    const onSelectCuenta = (value, indx) => {
        let cuenta = resultCuentasContables.filter((r) => r.codigo === value)[0];
        console.info("cuenta=>", cuenta);
        let items = [...detalle];
        let item = {...items[indx]};
        item.id = cuenta.id;
        item.codigo = cuenta.codigo;
        item.cuenta = cuenta.text;
        items[indx] = item;

        setDetalle(items);

        OnNuevaLinea();
    };

    const onRemoverLinea = (index) => {
        let items = [...detalle];
        setDetalle(items.filter((val, idx) => index != idx));
    };

    const actualizaGrid = (row, col, val) => {
        console.log("row=>", row, "col=>", col, "val=>", val);
        let items = [...detalle];
        let item = {...items[row]};
        item[col] = val;
        items[row] = item;
        setDetalle(items);
    };

    const EditableCell = ({valor, row, column, updateMyData}) => {
        const [value, setValue] = useState(valor);

        return (
            <NumberFormat
                prefix={""}
                value={value}
                customInput={Input}
                displayType={"input"}
                thousandSeparator={true}
                onBlur={(event) => {
                    updateMyData(row, column, value);
                }}
                onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    setValue(value);
                }}
            />
        );
    };

    return (
        <>
            <Button onClick={OnNuevaLinea}>Agregar línea</Button>
            <Table
                columns={
                    <>
                        <TableColumn minWidth={200}>
                            <Label>Código</Label>
                        </TableColumn>
                        <TableColumn minWidth={500}>
                            <Label>Cuenta</Label>
                        </TableColumn>
                        <TableColumn minWidth={300}>
                            <Label>Debe</Label>
                        </TableColumn>
                        <TableColumn minWidth={300}>
                            <Label>Haber</Label>
                        </TableColumn>
                        <TableColumn></TableColumn>
                    </>
                }
            >
                {detalle &&
                    detalle.map((rw, indx) => {
                        if (rw.editable)
                            return (
                                <TableRow key={indx}>
                                    <TableCell style={{padding: "0"}}>
                                        <AutoComplete
                                            key={indx}
                                            dropdownMatchSelectWidth={300}
                                            style={{width: "100%"}}
                                            options={options}
                                            onSelect={(v) => onSelectCuenta(v, indx)}
                                            onSearch={handleSearch}
                                            defaultValue={rw.codigo}
                                        >
                                            <Input.Search                                                
                                                size="middle"
                                                placeholder="Digíte el código o nombre de la cuenta"
                                                enterButton
                                            />
                                        </AutoComplete>
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <Label>{rw.cuenta}</Label>
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <EditableCell
                                            valor={rw.debe}
                                            row={indx}
                                            column={"debe"}
                                            updateMyData={actualizaGrid}
                                        />
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <EditableCell
                                            valor={rw.haber}
                                            row={indx}
                                            column={"haber"}
                                            updateMyData={actualizaGrid}
                                        />
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Button
                                                onClick={() => onRemoverLinea(indx)}
                                                icon="delete"
                                            ></Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        else
                            return (
                                <TableRow key={indx}>
                                    <TableCell style={{padding: "0"}}>
                                        <Label>{rw.codigo}</Label>
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <Label>{rw.cuenta}</Label>
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <NumberFormat
                                            value={rw.debe}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value, props) => (
                                                <Input bordered={false} value={value} {...props} />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}>
                                        <NumberFormat
                                            value={rw.haber}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value, props) => (
                                                <Input bordered={false} value={value} {...props} />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell style={{padding: "0"}}></TableCell>
                                </TableRow>
                            );
                    })}
                {
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell style={{padding: "0"}}>
                            <NumberFormat
                                value={totalDebe}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value, props) => (
                                    <Input bordered={false} value={value} {...props} />
                                )}
                            />
                        </TableCell>
                        <TableCell style={{padding: "0"}}>
                            <NumberFormat
                                value={totalHaber}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value, props) => (
                                    <Input bordered={false} value={value} {...props} />
                                )}
                            />
                        </TableCell>
                        <TableCell style={{padding: "0"}}></TableCell>
                    </TableRow>
                }
            </Table>
        </>
    );
};

export default GridDetalleContable;
