import React, { useContext, useEffect, useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ERPMasterContext } from '../contexts/ERPMasterAuth';
import { useNavigate } from 'react-router-dom';
const LoginFromERP = () => {
  const [searchParams] = useSearchParams();
  const {setid_compania,setid_usuario,setToken,setdespacho} = useContext(ERPMasterContext);
  const nav = new useNavigate();

  useEffect(() => {
    let token = searchParams.get("token")
    if (token){
      setToken(searchParams.get("token"))
      setdespacho(false);
      setid_compania(0)
      setid_usuario(0)
      nav("/")
    }
    else {
      setid_compania(searchParams.get("id_compania"))
      setid_usuario(searchParams.get("id_usuario"))
      setdespacho(true);
      let redirect = searchParams.get("redirect");
      if (redirect)
        nav("/despacho/" + redirect)
      else
        nav("/despacho")
    }   
  }, [])
  
  return (
    <div>{searchParams.get("valor")}</div>
  )
}
//http://localhost:3000/login_erp?id_compania=21&id_usuario=2024
//http://localhost:3000/login_erp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZF9jb21wYW5pYSI6IjIxIiwiaWRfdXN1YXJpbyI6IjIwMjQiLCJ1bmlxdWVfbmFtZSI6InVzdWFyaW9wcmluY2lwYWxAZ21haWwuY29tIiwibmJmIjoxNjUwMjA3NzE0LCJleHAiOjE2NTAyMDk1MTQsImlhdCI6MTY1MDIwNzcxNH0.a-0XpcbAjlWr_Biq2RATsa6SCKW1EdrMNOevSqNY9QA
//http://192.168.0.73:3000/login_erp?id_compania=21&id_usuario=2024
export default LoginFromERP