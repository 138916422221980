import React, {useRef, useState, useEffect} from "react";
import {
    FormItem,
    Input,
    InputType,
    Grid,
    Wizard,
    WizardStep,
    Title,
    Button,
    ButtonDesign,
    Table,
    TableColumn,
    Label,
    TableRow,
    TableCell,
    Toast,
    Dialog,
    Bar,
    Icon,
} from "@ui5/webcomponents-react";
import api from "../servicios/api";
import Boton from "../components/Boton";
import {Funciones} from "../servicios/utilidades";
import {useLocalStorage} from "../servicios/useLocalStorage";
import {AbrirReporte, reporte} from "../servicios/reportes";

const ListaCargas = () => {
    const [listadoDocumentos, setlistadoDocumentos] = useState([]);
    const [id_usuario] = useLocalStorage("usr");
    const [id_compania] = useLocalStorage("comp");
    const [id_carga, setid_carga] = useState(null);
    const dialogRef = useRef();
    const toast = useRef();


    const handleOpen = (e) => {
        dialogRef.current.show();
    };
    const handleClose = () => {
        dialogRef.current.close();
    };

    useEffect(() => {
        getListadaDocumentosCarga();
    }, []);

    const getListadaDocumentosCarga = async () => {
        const res = await api.get(
            `/api/Despacho/GetListaCargasPreparacion?id_usuario_carga=${id_usuario}&finalizado=null`,
            {
                headers: {
                    id_compania: Number(
                        localStorage.getItem("comp").replace('"', "").replace('"', "")
                    ),
                    id_usuario: Number(
                        localStorage.getItem("usr").replace('"', "").replace('"', "")
                    ),
                },
            }
        );
        setlistadoDocumentos(res.data.data);
    };

    const Anular = (id) => {
        setid_carga(id);
        handleOpen();
    };

    const AnularDocumento = async () => {
        let proRes = await api.post(
            "/api/Despacho/AnularDocumentoCarga",
            {
                id: id_carga,
            },
            {
                headers: {
                    id_compania: Number(
                        localStorage.getItem("comp").replace('"', "").replace('"', "")
                    ),
                    id_usuario: Number(
                        localStorage.getItem("usr").replace('"', "").replace('"', "")
                    ),
                },
            }
        );

        handleClose();

        if (proRes.data.resultado) {
            toast.current.show();
            await getListadaDocumentosCarga()
        } else {

        }
    };

    const Imprimir = () => {};

    const TablaDocumentos = ({items}) => {
        return (
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Documento</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Zona</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} popinText="Fecha de preparación" demandPopin>
                            <Label>Fecha Preparado</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} popinText="Fonalizado" demandPopin>
                            <Label>Finalizado</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} popinText="Comentario" demandPopin>
                            <Label>Comentario</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label></Label>
                        </TableColumn>
                    </>
                }
            >
                {listadoDocumentos &&
                    listadoDocumentos.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Label>{item.documento}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.zona}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.fecha_preparacion}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.finalizado && <Icon name="accept" />} {item.fecha_finalizacion}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.comentario_preparacion}</Label>
                            </TableCell>
                            <TableCell>
                                <Button
                                    icon="print"
                                    design={ButtonDesign.Emphasized}
                                    onClick={() =>
                                        AbrirReporte(
                                            reporte.RPT_COMPROBANTE_CARGA +
                                                `?IDCOMPANIA=${id_compania}&IDUSUARIO=${id_usuario}&mov=${item.id}`
                                        )
                                    }
                                >
                                    Imprimir
                                </Button>{" "}
                                <Button
                                    icon="delete"
                                    design={ButtonDesign.Emphasized}
                                    onClick={() => Anular(item.id)}
                                >
                                    Anular
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
            </Table>
        );
    };

    return (
        <div>
            <Dialog
                ref={dialogRef}
                footer={
                    <Bar
                        endContent={
                            <>
                                <Button onClick={AnularDocumento}>Si</Button>
                                <Button onClick={handleClose}>No</Button>
                            </>
                        }
                    ></Bar>
                }
            >
                Está seguro que desea anular la carga?
            </Dialog>
            <TablaDocumentos />
            <Toast ref={toast}>Anular con éxito</Toast>
        </div>
    );
};

export default ListaCargas;
