import React from "react";
import SAPui from "../../components/refSAPui";

const GridDocumentosRAPIFE = () => {
  const COLUMNS = [
    {
      Header: "Consecutivo",
      accessor: "emisor_consecutivo",
    },
    {
      Header: "Fecha Emisión",
      accessor: "fecha_emision",
    },
    {
      Header: "Emisor",
      accessor: "emisor",
    },
    {
      Header: "",
      accessor: "moneda",
    },
    {
      Header: "Impuesto",
      accessor: "impuesto",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
      responsivePopIn: true,
      responsiveMinWidth: 801,
      id: "actions",
      Header: "",
      width: 100,
      disableResizing: true,
      Cell: (instance) => {
        return (
          <SAPui.FlexBox>
            <SAPui.Button icon="begin" />
          </SAPui.FlexBox>
        );
      },
    },
  ];

  const TESDATA = [
    {
      emisor_consecutivo: "0000000000",
      fecha_emision: "15/03/2022",
      emisor: "EMISOR DOCUMENTO PROVEEDOR",
      moneda: "CRC",
      impuesto: 1300,
      total: 10000,
    },
    {
      emisor_consecutivo: "0000000055",
      fecha_emision: "15/03/2022",
      emisor: "PROVEEDOR DE PRUEBAS",
      moneda: "USD",
      impuesto: 1300,
      total: 10000,
    },
  ];

  return (
    <>
      <SAPui.AnalyticalTable columns={COLUMNS} data={TESDATA} />
    </>
  );
};

export default GridDocumentosRAPIFE;
