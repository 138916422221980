import Dexie from "dexie";

export const dbDexie = new Dexie("ERPMasterInit");

dbDexie.version(1).stores({
    monedas: "++id, codigo, simbolo, moneda, tipo_cambio_venta, tipo_cambio_compra",
    moneda_base: "++id",
});

// //borrar valores
dbDexie.monedas.clear();
dbDexie.moneda_base.clear();

export const metodosDexie = {
    addMonedas: async (listado) => {
        try {
            dbDexie.monedas
                .bulkAdd(listado)
                .then(function () {
                    console.log("Monedas creadas");
                })
                .catch(Dexie.BulkError, function (e) {
                    console.error(e);
                });
        } catch (error) {}
    },
    addMonedaBase: async (id) => {
        try {
            dbDexie.moneda_base
                .add({id : id})
                .then(function () {
                    console.log("Moneda base creada");
                })
                .catch(function (e) {
                    console.error(e);
                });
        } catch (error) {}
    },
};
