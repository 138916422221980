import React, {useRef, useState, useEffect} from "react";
import {
    FormItem,
    Input,
    InputType,
    Grid,
    Wizard,
    WizardStep,
    Title,
    Button,
    ButtonDesign,
    Table,
    TableColumn,
    Label,
    TableRow,
    TableCell,
    Icon,
    MessageStrip,
    MessageStripDesign,
} from "@ui5/webcomponents-react";
import api from "../servicios/api";
import Boton from "../components/Boton";
import {Funciones} from "../servicios/utilidades";
import {useLocalStorage} from "../servicios/useLocalStorage";
const {SumarArray} = Funciones;

const Verificacion = () => {
    const step1 = useRef();
    const step2 = useRef();
    const inputLector = useRef();
    const [listadoDocumentos, setlistadoDocumentos] = useState([]);
    const [detalleCarga, setdetalleCarga] = useState([]);
    const [listaCodigosLeidos, setlistaCodigosLeidos] = useState([]);
    const [idDocumentoCarga, setidDocumentoCarga] = useState(0);
    const [procesando, setprocesando] = useState(false);
    const [mensajeLecturaDesign, setMensajeLecturaDesign] = useState("");
    const [mensajeLectura, setMensajeLectura] = useState("");
    const [terminado, setTerminado] = useState(false);
    const [id_usuario] = useLocalStorage("usr");

    const getListadaDocumentosCarga = async () => {
        const res = await api.get(
            `/api/Despacho/GetListaCargasPreparacion?id_usuario_carga=${id_usuario}&finalizado=false`,
            {
                headers: {
                    id_compania: Number(
                        localStorage.getItem("comp").replace('"', "").replace('"', "")
                    ),
                    id_usuario: Number(
                        localStorage.getItem("usr").replace('"', "").replace('"', "")
                    ),
                },
            }
        );
        setlistadoDocumentos(res.data.data);
    };

    useEffect(() => {
        getListadaDocumentosCarga();
    }, []);

    const TablaFacturas = ({items, ocupado}) => {
        return (
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Documento</Label>
                        </TableColumn>
                        <TableColumn minWidth={600}>
                            <Label>Cliente</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Bultos</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Despachados</Label>
                        </TableColumn>
                    </>
                }
            >
                {items.map((item) => {
                    
                    if (item.despachado < item.bultos)
                        return (
                            <TableRow key={item.id_factura}>
                                <TableCell>
                                    <Label>{item.documento}</Label>
                                </TableCell>
                                <TableCell>
                                    <Label>{item.cliente}</Label>
                                </TableCell>
                                <TableCell>
                                    <Label>{item.bultos}</Label>
                                </TableCell>
                                <TableCell>
                                    <Label>{item.despachado}</Label>
                                </TableCell>
                            </TableRow>
                        );
                })}
            </Table>
        );
    };

    const getDetalleCarga = async (id) => {
        const res = await api.get(`/api/Despacho/GetDetalleCarga?id=${id}`);
        setdetalleCarga(res.data.data);
    };

    const goToStep2 = (id) => {
        step2.current.selected = true;
        step2.current.disabled = false;
        step1.current.disabled = true;
        setidDocumentoCarga(id);
        getDetalleCarga(id);
        setTimeout(() => {
            inputLector.current.focus();
        }, 200);
    };

    const OnEnter = (e) => {
        if (e.key === "Enter" && e.target.value) {
            let nDocumento = e.target.value.substring(0, 1);
            let documento = e.target.value.substring(e.target.value.indexOf("F")).replace("'", "-");

            if (listaCodigosLeidos.some((r) => r.codigo == e.target.value)) return;

            if (!detalleCarga.some((r) => r.documento.includes(documento)) || isNaN(nDocumento)) {
                setMensajeLectura("Bulto inválido");
                setMensajeLecturaDesign(MessageStripDesign.Warning);
                return;
            }

            setlistaCodigosLeidos([...listaCodigosLeidos, {codigo: e.target.value}]);

            detalleCarga.filter((r) => r.documento == documento)[0].despachado += 1;
            setdetalleCarga(detalleCarga);

            inputLector.current.value = "";
            inputLector.current.focus();
        }
    };

    const MensajeUser = ({design, mensaje, onClose}) => {
        if (!mensaje) return null;
        return (
            <MessageStrip design={design} onClose={onClose}>
                {mensaje}
            </MessageStrip>
        );
    };

    const TablaDocumentos = ({items}) => {
        return (
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Documento</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Zona</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} popinText="Fecha de preparación" demandPopin>
                            <Label>Fecha Preparado</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} popinText="Comentario" demandPopin>
                            <Label>Comentario</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label></Label>
                        </TableColumn>
                    </>
                }
            >
                {listadoDocumentos &&
                    listadoDocumentos.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Label>{item.documento}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.zona}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.fecha_preparacion}</Label>
                            </TableCell>
                            <TableCell>
                                <Label>{item.comentario_preparacion}</Label>
                            </TableCell>
                            <TableCell>
                                <Button
                                    design={ButtonDesign.Emphasized}
                                    onClick={() => goToStep2(item.id)}
                                >
                                    Seleccionar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
            </Table>
        );
    };

    const finalizarVerificacion = async () => {
        let totalBultos = SumarArray(detalleCarga, "bultos");

        if (idDocumentoCarga == 0) {
            setMensajeLectura("Documento de carga inválido");
            setMensajeLecturaDesign(MessageStripDesign.Warning);
            return;
        }

        if (totalBultos != listaCodigosLeidos.length) {
            setMensajeLectura("La cantidad de bultos leídos, no coincide con la carga");
            setMensajeLecturaDesign(MessageStripDesign.Warning);
            return;
        }

        try {
            setprocesando(true);

            let proRes = await api.post("/api/Despacho/ProcesarPreparacionCarga", {
                id: idDocumentoCarga,
                comentario_finalizacion: "",
            });

            setprocesando(false);

            if (proRes.data.resultado) {
                setMensajeLecturaDesign(MessageStripDesign.Positive);
                setMensajeLectura(proRes.data.mensaje);
                setTerminado(true);
            } else {
                setMensajeLectura(proRes.data.mensaje);
                setMensajeLecturaDesign(MessageStripDesign.Warning);
            }
        } catch (error) {
            setprocesando(false);

            setMensajeLectura(JSON.stringify(error));
            setMensajeLecturaDesign(MessageStripDesign.Negative);
        }
    };

    return (
        <>
            <Wizard>
                <WizardStep icon="product" titleText="Entrega" selected ref={step1}>
                    <Title>1. Seleccione documento de entrega</Title>
                    <TablaDocumentos />
                </WizardStep>

                <WizardStep icon="hint" titleText="Verificación" disabled ref={step2}>
                    <Title>2. Verificación de bultos</Title>

                    <Grid>
                        <div>
                            <FormItem label={"Código de bulto"}>
                                <Input
                                    icon={<Icon name="bar-code"></Icon>}
                                    id="input-Lector"
                                    ref={inputLector}
                                    type={InputType.Text}
                                    onKeyPress={OnEnter}
                                />
                            </FormItem>
                        </div>
                        <div>Bultos leídos {listaCodigosLeidos.length}</div>
                    </Grid>

                    <TablaFacturas items={detalleCarga} />

                    <br />

                    <MensajeUser
                        design={mensajeLecturaDesign}
                        mensaje={mensajeLectura}
                        onClose={() => setMensajeLectura("")}
                    />

                    <br />
                    <br />

                    <Boton
                        design={ButtonDesign.Emphasized}
                        onClick={finalizarVerificacion}
                        id="finalizeBtn"
                        disabled={terminado}
                        ocupado={procesando}
                    >
                        Finalizar
                    </Boton>
                </WizardStep>
            </Wizard>
        </>
    );
};

export default Verificacion;
