import React, {useState} from "react";
import {ThemeProvider} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import "./App.css";
import Menu from "./general/Menu";
import {AppRouter} from "./AppRouter";
import TabTask from "./general/TabTask";
import {GeneralContextProvider} from "./contexts/GeneralContext";
import LayoutApp from "./components/LayoutApp";
import "antd/dist/antd.css";
import Login from "./cuenta/Login";
import {ERPMasterProvider} from "./contexts/ERPMasterAuth";

function App() {

    return (
        <ThemeProvider>
            <GeneralContextProvider>
                <ERPMasterProvider>
                    <LayoutApp />
                </ERPMasterProvider>
            </GeneralContextProvider>
        </ThemeProvider>
    );
}

export default App;
