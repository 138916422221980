import React, {createContext} from "react";
import {useLocalStorage} from "../servicios/useLocalStorage";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

export const GeneralContext = createContext();

export const GeneralContextProvider = ({children}) => {
    const nav = useNavigate();

    let arrTiposCredito = [];
    arrTiposCredito.push({id: 1, codigo: "01", descripcion: "Contado", escredito: false});
    arrTiposCredito.push({id: 2, codigo: "02", descripcion: "Crédito a 1 día", escredito: true});
    arrTiposCredito.push({id: 3, codigo: "03", descripcion: "Crédito a 5 días", escredito: true});

    const [misTareas, setMisTareas] = useLocalStorage("tareas", []);
    const [tareaActual, setTareaActual] = useLocalStorage("tarea_actual", "");

    const getRandom = () => {
        const min = 1;
        const max = 9999999;
        const rand = parseInt(min + Math.random() * (max - min));
        return rand;
    };

    const NuevaTarea = (id, title, route, UsaSesion) => {
        let sessionID = 0;
        if (UsaSesion) sessionID = getRandom();

        if (misTareas.filter((r) => r.id === id && r.sesion === sessionID).length > 0) return;
        setMisTareas([
            ...misTareas,
            {id: id, title: title, route: `${route}?sesion=${sessionID}`, sesion: sessionID},
        ]);
        nav(`${route}?sesion=${sessionID}`);
        setTareaActual(id + "|" + sessionID);
    };

    const CerrarTarea = (id, sesion) => {
        if (misTareas.filter((r) => r.id === id && r.sesion === Number(sesion)).length === 0)
            return;
        setMisTareas(misTareas.filter((r) => r.id !== id && r.sesion !== Number(sesion)));
    };

    const isMobileDevice = useMediaQuery({
        query: "(max-device-width: 480px)",
    });

    const isTabletDevice = useMediaQuery({
        query: "(min-device-width: 768px)",
    });

    const isLaptop = useMediaQuery({
        query: "(min-device-width: 1024px)",
    });

    return (
        <GeneralContext.Provider
            value={{
                NuevaTarea,
                CerrarTarea,
                misTareas,
                setTareaActual,
                tareaActual,
                isMobileDevice,
                isTabletDevice,
                isLaptop,
            }}
        >
            {children}
        </GeneralContext.Provider>
    );
};
