import React, {useState} from "react";
import {
  Form,
  TabContainer,
  Tab,
  Grid,
  Panel,
  Title,
  TitleLevel,
  FormGroup,
  FormItem,
  Input,
  CheckBox,
  Label,
  InputType,
  Select,
  Option,
  DatePicker,
  TextArea,
  Link,
  Table,
  TableColumn,
  TableCell,
  TableRow,
  ResponsiveGridLayout,
  Button,
  List,
  StandardListItem,
  FCLLayout,
  FlexibleColumnLayout,
} from "@ui5/webcomponents-react";
import SerieLoteDetalle from "../inventario/SerieLoteDetalle";
import GridLogs from "../general/GridLogs";
import SeleccionCliente from "../general/SeleccionCliente";

const Venta = () => {
  const [detalleFactura, setdetalleFactura] = useState([
    {index: 1, codigo: "000001", nombre: ""},
  ]);

  const [layout, setlayout] = useState(FCLLayout.OneColumn);
  const [layoutDetalle, setlayoutDetalle] = useState(
    FCLLayout.TwoColumnsMidExpanded
  );

  const TablaDetalle = ({items, ocupado}) => {
    return (
      <Table
        busy={ocupado}
        columns={
          <>
            <TableColumn>
              <Label>Código</Label>
            </TableColumn>
            <TableColumn>
              <Label>Artículo</Label>
            </TableColumn>
            <TableColumn>
              <Label>Cantidad</Label>
            </TableColumn>
            <TableColumn>
              <Label>Lista/P</Label>
            </TableColumn>
            <TableColumn>
              <Label>Precio/U</Label>
            </TableColumn>
            <TableColumn>
              <Label>Descuento/U</Label>
            </TableColumn>
            <TableColumn>
              <Label>% Descuento/U</Label>
            </TableColumn>
            <TableColumn>
              <Label>Total</Label>
            </TableColumn>
          </>
        }
      >
        {items.map((item) => (
          <TableRow key={item.index}>
            <TableCell>{item.codigo}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        ))}
      </Table>
    );
  };

  return (
    <>
      <TabContainer>
        <Tab text="General" icon="menu" selected>
          <Panel headerText={"Parámetros de venta"}>
            <Form
              columnsXL={2}
              columnsL={2}
              columnsM={2}
              columnsS={1}
              labelSpanXL={1}
              labelSpanL={4}
              labelSpanM={4}
            >
              <FormGroup titleText={"General"}>
                <FormItem label={"Departamento"}>
                  <Select></Select>
                </FormItem>
                <FormItem label={"Moneda"}>
                  <Select></Select>
                </FormItem>
                <FormItem label={"Lista de precio"}>
                  <Select></Select>
                </FormItem>
                <FormItem label={"Vendedor"}>
                  <Select></Select>
                </FormItem>
                <FormItem label={"Fecha"}>
                  <DatePicker />
                </FormItem>
              </FormGroup>
              <FormGroup titleText={"Datos de cliente"}>
                <FormItem>
                  <SeleccionCliente />
                </FormItem>
                <FormItem label={"Nombre"}>
                  <Input type={InputType.Text} />
                </FormItem>

                <FormItem label={"Identidad"}>
                  <Input type={InputType.Text} />
                </FormItem>

                <FormItem label={"Teléfono 1"}>
                  <Input type={InputType.Text} />
                </FormItem>

                <FormItem label="Dirección">
                  <TextArea
                    rows={5}
                    style={{
                      width: "210px",
                    }}
                  />
                </FormItem>
              </FormGroup>
            </Form>
          </Panel>

          <Table>
            <TableRow>
              <TableCell>
                <Label>Código:</Label>
                <Input />
              </TableCell>
              <TableCell>
                <Label>Cantidad:</Label>
                <Input type="Number" maxlength="3" showClearIcon={true}/>
              </TableCell>
              <TableCell>
                <Label>Descuento:</Label>
                <Input />
              </TableCell>
              <TableCell>
              <Button>Agregar</Button>
              </TableCell>
            </TableRow>
          </Table>
          <TablaDetalle items={detalleFactura}></TablaDetalle>
        </Tab>
        <Tab text="Otros" icon="add">
          <Form
            columnsXL={2}
            columnsL={2}
            columnsM={2}
            columnsS={1}
            labelSpanXL={1}
            labelSpanL={4}
            labelSpanM={4}
          >
            <FormItem label={"Comentario"}>
              <Input type={InputType.Text} />
            </FormItem>
            <FormItem label={"Transporte"}>
              <Select></Select>
            </FormItem>
            <FormItem label={"Origen de venta"}>
              <Select></Select>
            </FormItem>
            <FormItem label={"Imprime orden"}>
              <CheckBox checked />
            </FormItem>
            <FormGroup titleText={"Fiscal"}>
              <FormItem label={"Actividad económica"}>
                <Select></Select>
              </FormItem>
              <FormItem label={"Exportación"}>
                <CheckBox checked />
              </FormItem>
            </FormGroup>
          </Form>
        </Tab>
        <Tab text="Detalle contable" icon="settings">
          Content Tab 5
        </Tab>
      </TabContainer>
    </>
  );
};

export default Venta;
