import React, { useState, useRef, useEffect } from "react";
import {
  MessageStrip,
  MessageStripDesign,
  Dialog,
  Title,
  Bar,
  Icon,
  Button,
  Grid,
} from "@ui5/webcomponents-react";

const PopupAlerta = ({ open, title, onClose, children, clase, mensaje }) => {

  const dialogRef = useRef();

  useEffect(() => {

    if (open) dialogRef.current.show();
    else dialogRef.current.close();

  }, [open]);

  const MensajeRender = ({classe}) => {
    if (classe == "ok"){
      return (

        <MessageStrip design={MessageStripDesign.Positive} hideCloseButton={true}>
        {mensaje}
        </MessageStrip>
      )
    }
    else if (classe == "validacion"){
      return (
        <Grid>
          <div><Icon name="error"></Icon></div>
          <div>{mensaje}</div>
        </Grid>
      )

    }
    else if (classe == "error"){
      return (
        <Grid>
          <div><Icon name="alert"></Icon></div>
          <div>{mensaje}</div>
        </Grid>
      )
    }
    else {
      return (
        <Grid>
          <div><Icon name="alert"></Icon></div>
          <div>{classe}{mensaje}</div>
        </Grid>
      )
    }
  }

  return (
    <>
      <Dialog
        ref={dialogRef}      
        footer={<Bar endContent={<Button onClick={onClose}>Cerrar</Button>} />}
      >
        <MensajeRender classe={clase} />
        {children}
      </Dialog>
    </>
  );
};

export default PopupAlerta;
