import React from "react";
import { BusyIndicator, Button } from "@ui5/webcomponents-react";

const Boton = (args) => {
  
  if (args.ocupado) {
    console.info("ocuadoahora")
    return (
      <BusyIndicator delay={0} active={true} size="Small">
        <Button {...args}>{args.children}</Button>
      </BusyIndicator>
    );
  }

  return <Button {...args}>{args.children}</Button>;
  
};

export default Boton;
