import React, {useRef, useState} from "react";
import {
    SelectDialog,
    StandardListItem,
    Link,
    Loader,
    FormItem,
    Input,
    InputType,
} from "@ui5/webcomponents-react";
import {get} from "../servicios/api-m";

const SeleccionCliente = ({OnClienteSeleccion}) => {
    const dialogRef = useRef(null);
    const [Buscando, setBuscando] = useState(false);
    const [listaContactos, setListaContactos] = useState([]);

    const Buscar = async (e) => {
        setBuscando(true);
        try {
            let res = await get(`/api/General/AutoCompleteCliente?b=${e.detail.value}`);
            setListaContactos(res);
            setBuscando(false);
        } catch (error) {
            setBuscando(false);
        }
    };

    const onButtonClick = () => {
        dialogRef.current.show();
    };

    const SeleccionaCliente = async (e) => {
        let id = e.detail.selectedItems[0].dataset.id;

        setBuscando(true);

        try {
            let res = await get(`/api/General/GetContactoDetalle?id=${id}`);

            setBuscando(false);

            OnClienteSeleccion({respuesta: true, data: res});
        } catch (error) {
            setBuscando(false);

            OnClienteSeleccion({respuesta: false, data: error});
        }
    };

    return (
        <>
            <Link onClick={onButtonClick}>Seleccionar</Link>

            <SelectDialog
                onConfirm={SeleccionaCliente}
                onSearchInput={Buscar}
                ref={dialogRef}
                draggable={true}
                headerText={"Seleccionar cliente"}
            >
                {Buscando && <Loader />}
                {listaContactos.map((val, index) => {
                    return (
                        <StandardListItem
                            description={`Código: ${val.codigo}`}
                            key={index}
                            data-id={`${val.id}`}
                        >
                            {val.text}
                        </StandardListItem>
                    );
                })}
            </SelectDialog>
        </>
    );
};

export default SeleccionCliente;
