import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost/ERPWEBMASTER.Api/",
  baseURL: "https://api.erpmaster.software/",
  withCredentials: false,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});


export default api;
