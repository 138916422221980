import React from "react";
import { FilterBar, FilterGroupItem, ButtonDesign, Title, Input } from "@ui5/webcomponents-react";

const ListaArticulos = () => {
  return (
    <>
      <FilterBar showFilterConfiguration considerGroupName showSearchOnFiltersDialog>
        
          <FilterGroupItem label="Filter 1" groupName="Not customizable">
            <Input placeholder="Placeholder" />
          </FilterGroupItem>
          <FilterGroupItem label="Filter 2" groupName="Not customizable">
            <Input placeholder="Placeholder" />
          </FilterGroupItem>
        </FilterBar>
      ListaArticulos
    </>
  );
};

export default ListaArticulos;
