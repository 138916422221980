
const Funciones = {
    SumarArray(array, propiedad){
        return array.reduce( function(a, b){
            return a + b[propiedad];
        }, 0);
    },
    RellenaCeros(numero, cantidad){
        return (numero.toString().length < cantidad) ? this.RellenaCeros("0" + numero, cantidad) : numero;
    }
    
}



export { Funciones };