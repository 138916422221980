import React, {useContext} from "react";
import {ERPMasterContext} from "../contexts/ERPMasterAuth";
import {ProductSwitch, ProductSwitchItem} from "@ui5/webcomponents-react";
const Dashboard = () => {
    const {id_compania, id_usuario} = useContext(ERPMasterContext);
    return (
        <>
            <ProductSwitch>
                <ProductSwitchItem titleText="Home" subtitleText="Central Home" icon="home" />
                <ProductSwitchItem
                    titleText="Analytical Cloud"
                    subtitleText="Analytical Cloud"
                    icon="business-objects-experience"
                />
                <ProductSwitchItem titleText="Catalog" subtitleText="Ariba" icon="contacts" />
                <ProductSwitchItem
                    titleText="Travel & Expense"
                    subtitleText="Concur"
                    icon="flight"
                />
            </ProductSwitch>
        </>
    );
};

export default Dashboard;
