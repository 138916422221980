import React, {useEffect, useContext, useState} from "react";
import {Routes, Route} from "react-router-dom";
import NoExiste from "./general/NoExiste";
import IndexVenta from "./venta/IndexVenta";
import ListadoVenta from "./venta/Listado";
import ReportesVentas from "./venta/ReportesVentas";
import Venta from "./venta/Venta";
import Dashboard from "./general/Dashboard";
import IndexDespacho from "./despacho/IndexDespacho";
import Preparacion from "./despacho/Preparacion";
import Verificacion from "./despacho/Verificacion";
import LoginFromERP from "./general/LoginFromERP";
import ListaArticulos from "./mantenimiento/ListaArticulos";
import FormAsistenteImportacionCompra from "./inventario/FormAsistenteImportacionCompra";
import CuentaXCobrar from "./cxc/CuentaXCobrar";
import CXCEmitidas from "./cxc/CXCEmitidas";
import {get} from "./servicios/api-m";
import {useSearchParams} from "react-router-dom";
import NoAutorizado from "./general/NoAutorizado";
import api from "./servicios/api";
import ListaCargas from "./despacho/ListaCargas";

export const AppRouter = () => {
    const [searchParams] = useSearchParams();

    const ValidaAutenticacion = ({children}) => {
        const [estadoAut, setestadoAut] = useState(null);

        useEffect(() => {
            const validacionSesionApi = async () => {
                try {
                    let res = await get("/api/General/ValidaSesion");
                    return res == null ? false : res;
                } catch (error) {
                    return false;
                }
            };

            validacionSesionApi().then((val) => {
                console.warn("==============>", val);
                setestadoAut(val);
            });
        }, []);

        return (
            <>
                { estadoAut == null ? (<>Validando...</>) : estadoAut === false ? <NoAutorizado/> : (<>{children}</>) }
            </>
        );
    };

    return (
        <>
            <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/login_erp" element={<LoginFromERP />} />
                {/* Mantenimiento */}
                <Route
                    path="/mantenimiento/articulos"
                    element={
                        <ValidaAutenticacion>
                            <ListaArticulos />
                        </ValidaAutenticacion>
                    }
                />

                {/* Inventario */}
                <Route
                    path="/inventario/importacion-rapife"
                    element={
                        <ValidaAutenticacion>
                            <FormAsistenteImportacionCompra />
                        </ValidaAutenticacion>
                    }
                />

                {/* Despacho */}
                <Route
                    path="/despacho"
                    element={
                        // <ValidaAutenticacion>
                            <IndexDespacho />
                        // </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/despacho/cargas"
                    element={
                        // <ValidaAutenticacion>
                            <ListaCargas />
                        // </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/despacho/preparacion"
                    element={
                        // <ValidaAutenticacion>
                            <Preparacion />
                        // </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/despacho/verificacion"
                    element={
                        // <ValidaAutenticacion>
                            <Verificacion />
                        // </ValidaAutenticacion>
                    }
                />

                {/* Venta */}
                <Route
                    path="/venta"
                    element={
                        <ValidaAutenticacion>
                            <IndexVenta />
                        </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/venta/emitidas"
                    element={
                        <ValidaAutenticacion>
                            <ListadoVenta />
                        </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/venta/nueva"
                    element={
                        <ValidaAutenticacion>
                            <Venta />
                        </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/venta/reportes"
                    element={
                        <ValidaAutenticacion>
                            <ReportesVentas />
                        </ValidaAutenticacion>
                    }
                />

                {/* CXC */}
                {/* <Route path="/cxc" element={<IndexVenta />} /> */}
                <Route
                    path="/cxc/emitidas"
                    element={
                        <ValidaAutenticacion>
                            <CXCEmitidas />
                        </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/cxc/nueva"
                    element={
                        <ValidaAutenticacion>
                            <CuentaXCobrar/>
                        </ValidaAutenticacion>
                    }
                />
                <Route
                    path="/cxc/editar/:id"
                    element={
                        <ValidaAutenticacion>
                            <CuentaXCobrar/>
                        </ValidaAutenticacion>
                    }
                />
                {/* <Route path="/cxc/reportes" element={<ReportesVentas />} /> */}
                <Route path="*" element={<NoExiste />} />
            </Routes>
        </>
    );
};
