import React from "react";
import {Input, InputType, Icon, Link  } from "@ui5/webcomponents-react";

const InputLink = ({onLinkClick, ...inputPropsx}) => {
    const IconOpen = (props) => {
        return <Link slot={props.slot}><Icon onClick={onLinkClick} name="inspect" /></Link>;
    };

    return <Input {...inputPropsx} icon={<IconOpen />} />;
};

export default InputLink;
