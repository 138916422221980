import React from "react";
import { AnalyticalTable } from "@ui5/webcomponents-react";

const ListadoVenta = () => {
  const data = [
    {
      departamento: "Peter Franco",
      documento: 22,
      fecha: '05/05/2022',
      cliente: 'Nombre de cliente',
      total : 15555
    },
    {
      departamento: "Peter Franco",
      documento: 22,
      fecha: '05/05/2022',
      cliente: 'Nombre de cliente',
      total : 15555
    },
  ];

  const columns = [
    {
      Header: "Departamento",
      accessor: "departamento",
      responsivePopIn: true,
      responsiveMinWidth: 400,
      PopInHeader: 'Departamento',
    },
    {
      Header: "Documento",
      accessor: "documento",
    },
    {
      Header: "Fecha",
      accessor: "fecha",
      responsivePopIn: true,
      responsiveMinWidth: 400,
      PopInHeader: 'Fecha',
    },
    {
      Header: "Cliente",
      accessor: "cliente",
    },
    {
      Header: "Total",
      accessor: "total",
    },
  ];

  return (
    <div>
      <AnalyticalTable
        data={data}
        columns={columns}
        selectionMode="SingleSelect"        
      />
    </div>
  );
};

export default ListadoVenta;
