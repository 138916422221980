import React, {useRef, useEffect, useState, useContext} from "react";
import SAPui from "../components/refSAPui";
import api from "../servicios/api";
import moment from "moment";
import PopupAlerta from "../components/PopupAlerta";
import {useNavigate} from "react-router-dom";
import {AbrirReporte, reporte} from "../servicios/reportes";
import {ERPMasterContext} from "../contexts/ERPMasterAuth";
import Boton from "../components/Boton";

const Preparacion = () => {
    const {id_usuario, id_compania} = useContext(ERPMasterContext);
    const toast = useRef();
    const [listadoFacturas, setlistadoFacturas] = useState([]);
    const [departamentos, setdepartamentos] = useState([]);
    const [vendedores, setvendedores] = useState([]);
    const [zonas, setzonas] = useState([]);
    const [respuesta, setRepuesta] = useState({
        mostrar: false,
        respuesta: false,
        mensaje: "",
        clase: "",
    });
    const [procesando, setprocesando] = useState(false);
    const [cargandoFacturas, setcargandoFacturas] = useState(false);
    const [mensajeToast, setmensajeToast] = useState("");
    const [idCarga, setidCarga] = useState(null);
    const [model, setmodel] = useState({
        departamento: 0,
        zona: 0,
        desde: null,
        hasta: null,
        usuario_carga: null,
        comentario: "",
    });
    const navigate = useNavigate();

    const setValueForm = (e, valor) => {
        setmodel({
            ...model,
            [e.target.id]: valor,
        });
    };

    const step2 = useRef();
    const step3 = useRef();

    useEffect(() => {
        async function fetchData() {
            const res = await api.post("/api/Despacho/initPreparacion");
            setdepartamentos(res.data.data.departamentos);
            setzonas(res.data.data.zonas);
            setvendedores(res.data.data.vendedores);
        }
        fetchData();
    }, []);

    const goToStep2 = async () => {
        if (!model.departamento) {
            setmensajeToast("Seleccione el departamento");
            toast.current.show();
            return;
        }

        if (!model.zona) {
            setmensajeToast("Seleccione la zona");
            toast.current.show();
            return;
        }

        if (!model.desde || !model.hasta) {
            setmensajeToast("Fechas inválidas");
            toast.current.show();
            return;
        }

        let desde = moment(model.desde, "DD/MM/YYYY").format("YYYY-MM-DD");
        let hasta = moment(model.hasta, "DD/MM/YYYY").format("YYYY-MM-DD");

        step2.current.selected = true;
        step2.current.disabled = false;

        setcargandoFacturas(true);
        let res = await api.get(
            `/api/Despacho/GetListaFacturas?dpto=${model.departamento}&zona=${model.zona}&desde=${desde}&hasta=${hasta}`
        );

        setlistadoFacturas(res.data.data);
        setcargandoFacturas(false);
        console.warn(listadoFacturas);
    };

    const goToStep3 = () => {
        console.warn(model);
        step3.current.selected = true;
        step3.current.disabled = false;
    };

    const finalizeWizard = async () => {
        if (!model.usuario_carga) {
            setmensajeToast("Usuario de carga inválido");
            toast.current.show();
            return;
        }

        try {
            setprocesando(true);

            let desde = moment(model.desde, "DD/MM/YYYY").format("YYYY-MM-DD");
            let hasta = moment(model.hasta, "DD/MM/YYYY").format("YYYY-MM-DD");

            let proRes = await api.post("/api/Despacho/ProcesarPreparacionCarga", {
                id: 0,
                comentario_preparacion: model.comentario,
                id_usuario_carga: model.usuario_carga,
                detalle: listadoFacturas,
                filtro_desde: desde,
                filtro_hasta: hasta,
                filtro_dpto: model.departamento,
                filtro_zona: model.zona,
            });

            console.log(proRes.data.resultado);

            setprocesando(false);

            let clase = "";
            if (proRes.data.resultado) {
                clase = "ok";
                setidCarga(proRes.data.data);
            } else if (proRes.data.validacion) clase = "validacion";

            setRepuesta({
                mostrar: true,
                respuesta: proRes.data.resultado,
                mensaje: proRes.data.mensaje,
                clase: clase,
            });
        } catch (error) {
            setprocesando(false);

            setRepuesta({
                mostrar: true,
                respuesta: false,
                mensaje: error,
                clase: "error",
            });
        }
    };

    const cerrarPopup = () => {
        setRepuesta({...respuesta, mostrar: false});
        navigate("/despacho");
    };

    const TablaFacturas = ({items, ocupado}) => {
        return (
            <SAPui.Table
                busy={ocupado}
                columns={
                    <>
                        <SAPui.TableColumn>
                            <SAPui.Label>Documento</SAPui.Label>
                        </SAPui.TableColumn>
                        <SAPui.TableColumn minWidth={600}>
                            <SAPui.Label>Fecha</SAPui.Label>
                        </SAPui.TableColumn>
                        <SAPui.TableColumn minWidth={600}>
                            <SAPui.Label>Cliente</SAPui.Label>
                        </SAPui.TableColumn>
                        <SAPui.TableColumn>
                            <SAPui.Label>Dirección</SAPui.Label>
                        </SAPui.TableColumn>
                        <SAPui.TableColumn>
                            <SAPui.Label>Total</SAPui.Label>
                        </SAPui.TableColumn>
                        <SAPui.TableColumn>
                            <SAPui.Label>Bultos</SAPui.Label>
                        </SAPui.TableColumn>
                    </>
                }
            >
                {items.map((item) => (
                    <SAPui.TableRow key={item.id_factura}>
                        <SAPui.TableCell>
                            <SAPui.Label>{item.documento}</SAPui.Label>
                        </SAPui.TableCell>
                        <SAPui.TableCell>
                            <SAPui.Label>{item.fecha}</SAPui.Label>
                        </SAPui.TableCell>
                        <SAPui.TableCell>
                            <SAPui.Label>{item.cliente}</SAPui.Label>
                        </SAPui.TableCell>
                        <SAPui.TableCell>
                            <SAPui.Label>{item.direccion}</SAPui.Label>
                        </SAPui.TableCell>
                        <SAPui.TableCell>
                            <SAPui.Label>
                                {item.simbolo}{" "}
                                {item.total.toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                })}
                            </SAPui.Label>
                        </SAPui.TableCell>
                        <SAPui.TableCell>
                            <SAPui.Label>{item.bultos}</SAPui.Label>
                        </SAPui.TableCell>
                    </SAPui.TableRow>
                ))}
            </SAPui.Table>
        );
    };

    return (
        <>
            <SAPui.Toast ref={toast}>{mensajeToast}</SAPui.Toast>
            <SAPui.Wizard>
                <SAPui.WizardStep icon="product" titleText="Seleccionar facturas" selected>
                    <SAPui.Title>1. Seleccionar facturas</SAPui.Title>
                    <SAPui.Grid data-layout-span="XL8 L8 M8 S12">
                        <div>
                            <SAPui.FormItem label={"Departamento"}>
                                <SAPui.Select
                                    id="departamento"
                                    onChange={(e) =>
                                        setValueForm(e, e.detail.selectedOption.dataset.id)
                                    }
                                >
                                    <SAPui.Option selected key={0} data-id={0}>
                                        Seleccione
                                    </SAPui.Option>
                                    {departamentos.map((item) => (
                                        <SAPui.Option key={item.id} data-id={item.id}>
                                            {item.text}
                                        </SAPui.Option>
                                    ))}
                                </SAPui.Select>
                            </SAPui.FormItem>
                        </div>

                        <div>
                            <SAPui.FormItem label={"Zona"}>
                                <SAPui.Select
                                    id="zona"
                                    onChange={(e) =>
                                        setValueForm(e, e.detail.selectedOption.dataset.id)
                                    }
                                >
                                    <SAPui.Option selected key={0} data-id={0}>
                                        Seleccione
                                    </SAPui.Option>
                                    {zonas.map((item) => (
                                        <SAPui.Option se key={item.id} data-id={item.id}>
                                            {item.text}
                                        </SAPui.Option>
                                    ))}
                                </SAPui.Select>
                            </SAPui.FormItem>
                        </div>

                        <div>
                            <SAPui.FormItem label={"Fecha desde"}>
                                <SAPui.DatePicker
                                    formatPattern="dd/MM/yyyy"
                                    id="desde"
                                    onChange={(e) => setValueForm(e, e.detail.value)}
                                />
                            </SAPui.FormItem>
                        </div>
                        <div>
                            <SAPui.FormItem label={"Fecha hasta"}>
                                <SAPui.DatePicker
                                    formatPattern="dd/MM/yyyy"
                                    id="hasta"
                                    onChange={(e) => setValueForm(e, e.detail.value)}
                                />
                            </SAPui.FormItem>
                        </div>
                    </SAPui.Grid>
                    <br />
                    <SAPui.Button design={SAPui.ButtonDesign.Emphasized} onClick={goToStep2}>
                        Paso 2
                    </SAPui.Button>
                </SAPui.WizardStep>
                <SAPui.WizardStep icon="hint" titleText="Facturas a despachar" disabled ref={step2}>
                    <SAPui.Title>2. Facturas a despachar</SAPui.Title>

                    <TablaFacturas items={listadoFacturas} ocupado={cargandoFacturas} />

                    <br />

                    <SAPui.Button
                        id="goToStep3"
                        design={SAPui.ButtonDesign.Emphasized}
                        onClick={goToStep3}
                    >
                        Continuar
                    </SAPui.Button>
                </SAPui.WizardStep>
                <SAPui.WizardStep icon="lead" titleText="Finalizar" disabled ref={step3}>
                    <SAPui.Title>3. Finalizar</SAPui.Title>

                    <SAPui.FormItem label={"Usuario de carga"}>
                        <SAPui.Select
                            id="usuario_carga"
                            onChange={(e) => setValueForm(e, e.detail.selectedOption.dataset.id)}
                        >
                            <SAPui.Option selected key={0} data-id={0}>
                                Seleccione
                            </SAPui.Option>
                            {vendedores.map((item) => (
                                <SAPui.Option key={item.id} data-id={item.id}>
                                    {item.text}
                                </SAPui.Option>
                            ))}
                        </SAPui.Select>
                    </SAPui.FormItem>

                    <SAPui.FormItem label="Comentario">
                        <SAPui.TextArea
                            rows={5}
                            id="comentario"
                            onChange={(e) => setValueForm(e, e.target.value)}
                            style={{
                                width: "210px",
                            }}
                        />
                    </SAPui.FormItem>

                    <br />

                    <Boton
                        design={SAPui.ButtonDesign.Emphasized}
                        onClick={finalizeWizard}
                        id="finalizeBtn"
                        ocupado={procesando}
                    >
                        Finalizar
                    </Boton>
                </SAPui.WizardStep>
            </SAPui.Wizard>

            <PopupAlerta
                open={respuesta.mostrar}
                onClose={cerrarPopup}
                title="Preparación de carga"
                mensaje={respuesta.mensaje}
                clase={respuesta.clase}
            >
                {respuesta.respuesta && (
                    <SAPui.List>
                        <SAPui.StandardListItem
                            onClick={() =>
                                AbrirReporte(
                                    reporte.RPT_COMPROBANTE_CARGA +
                                        `?IDCOMPANIA=${id_compania}&IDUSUARIO=${id_usuario}&mov=${idCarga}`
                                )
                            }
                        >
                            <SAPui.Link>Ver reporte de carga</SAPui.Link>
                        </SAPui.StandardListItem>
                    </SAPui.List>
                )}
            </PopupAlerta>
        </>
    );
};

export default Preparacion;
