import React, {useContext} from "react";
import {SideNavigation, SideNavigationItem, SideNavigationSubItem} from "@ui5/webcomponents-react";
import {GeneralContext} from "../contexts/GeneralContext";

const Navegacion = () => {
    const {NuevaTarea, isMobileDevice} = useContext(GeneralContext);

    const OnSeleccion = (e) => {
        if (!e.detail.item.dataset.route) return;
        NuevaTarea(
            e.detail.item.dataset.id,
            e.detail.item.dataset.title,
            e.detail.item.dataset.route,
            e.detail.item.dataset.usasesion === "true"
        );
    };

    return (
        <SideNavigation
            collapsed={isMobileDevice}
            onSelectionChange={OnSeleccion}
            style={{width: "100%", height: "100%"}}
            fixedItems={
                <>
                    <SideNavigationItem text="Configuraciones" icon="history" />
                </>
            }
        >
            <SideNavigationItem text="Dashboard" icon="home" />
            <SideNavigationItem text="Mantenimiento" icon="database">
                <SideNavigationSubItem text="Contactos" />
                <SideNavigationSubItem
                    text="Artículos"
                    data-route="/mantenimiento/articulos"
                    data-id="mantenimiento-articulos"
                    data-title="Mis artículos"
                    data-usaSesion={true}
                />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Finanzas" icon="lead-outdated">
                <SideNavigationSubItem text="Movimientos bancarios" />
                <SideNavigationSubItem text="Conciliación bancaria" />
                <SideNavigationSubItem text="Movimientos de caja" />
                <SideNavigationSubItem text="Importación" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Inventarios" icon="bar-code">
                <SideNavigationSubItem text="Ordenes de compra" />
                <SideNavigationSubItem text="Compras de mercancía" />
                <SideNavigationSubItem text="Compras de mercancía (consolidadas)" />
                <SideNavigationSubItem text="Tomas físicas" />
                <SideNavigationSubItem text="Series y lotes" />
                <SideNavigationSubItem text="Disparadores" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Ventas" icon="sales-document">
                <SideNavigationSubItem text="Nueva venta" />
                <SideNavigationSubItem text="Emitidas" />
                <SideNavigationSubItem
                    text="Despachos"
                    data-route="/despacho"
                    data-id="despachos"
                    data-title="Despachos de mercancía"
                    data-usaSesion={false}
                />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Pedidos, proformas y apartados" icon="my-sales-order">
                <SideNavigationSubItem text="Nuevo" />
                <SideNavigationSubItem text="Emitidas" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Cuentas por cobrar" icon="batch-payments">
                <SideNavigationSubItem
                    text="Nueva"
                    data-route="/cxc/nueva"
                    data-id="cxc-nueva"
                    data-title="Nueva cuenta por cobrar"
                    data-usaSesion={true}
                />
                <SideNavigationSubItem text="Emitidas" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
            <SideNavigationItem text="Cuentas por pagar" icon="batch-payments">
                <SideNavigationSubItem text="Nueva" />
                <SideNavigationSubItem text="Emitidas" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>

            <SideNavigationItem text="CRM" icon="crm-sales">
                <SideNavigationSubItem text="Pipeline" />
                <SideNavigationSubItem text="Campañas" />
                <SideNavigationSubItem text="Configuraciones" />
                <SideNavigationSubItem text="Reportes" />
            </SideNavigationItem>
        </SideNavigation>
    );
};

export default Navegacion;
