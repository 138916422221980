const BASEReportes = "https://reportes.erpmaster.software/";

const reporte = {
    RPT_COMPROBANTE_CARGA : BASEReportes + "Render/GenerarReporteCarga"
}

function AbrirReporte(URL) {
    window.open(URL, '_blank').focus();
}

export { AbrirReporte, reporte }