import React, {useContext} from "react";
import {
    ShellBar,
    StandardListItem,
    ShellBarItem,
    GroupHeaderListItem,
} from "@ui5/webcomponents-react";
import {useNavigate} from "react-router-dom";
import {GeneralContext} from "../contexts/GeneralContext";

const Menu = () => {
    const nav = useNavigate();
    const {NuevaTarea} = useContext(GeneralContext);
    const HMenuClick = (e) => {
        nav(`${e.detail.item.dataset.route}`);        
    };

    return (
        <>
            <ShellBar
                primaryTitle="ERP Master"
                onMenuItemClick={HMenuClick}
                menuItems={
                    <>
                        <StandardListItem
                            icon="home"
                            data-id="5"
                            title="Despacho"
                            data-route="/despacho"
                        >
                            Despacho
                        </StandardListItem>
                        {/* <GroupHeaderListItem>Mantenimiento</GroupHeaderListItem>
            <StandardListItem
              title="Listado de artículos"
              data-route="/mantenimiento/articulos"
              data-id="5"
            >
              Artículos
            </StandardListItem>
            <StandardListItem>Contactos</StandardListItem>
            <StandardListItem>Reportes</StandardListItem>
            <GroupHeaderListItem>Ventas</GroupHeaderListItem>
            <StandardListItem
              title="Nueva venta"
              data-route="/venta/nueva"
              data-id="1"
            >
              Nueva
            </StandardListItem>
            <StandardListItem
              title="Ventas emitidas"
              data-route="/venta/emitidas"
              data-id="2"
            >
              Emitidas
            </StandardListItem>
            <StandardListItem
              title="Despachos"
              data-route="/despacho"
              data-id="3"
            >
              Despachos
            </StandardListItem>
            <StandardListItem
              title="Reportes de venta"
              data-route="/venta/reportes"
              data-id="4"
            >
              Reportes
            </StandardListItem>
            <GroupHeaderListItem>Cuentas por cobrar</GroupHeaderListItem>
            <StandardListItem
              title="Nueva cuenta por cobrar"
              data-route="/cxc/nueva"
              data-id="7"
            >
              Nueva
            </StandardListItem>
            <StandardListItem
              title="Cuentas por cobrar"
              data-route="/cxc/emitidas"
              data-id="6"
            >
              Emitidas
            </StandardListItem> */}
                    </>
                }
            >
                <ShellBarItem icon="person-placeholder" text="Mi usuario">
                    Mi usuario
                </ShellBarItem>
            </ShellBar>
        </>
    );
};

export default Menu;
