import api from "./api";

const get = async (arg) => {
    try {
        let res = await api.get(arg);
        return res.data;
    } catch (error) {        
        console.error("GET controlado ==>", error);
        return null;
    }
};

const post = async (arg) => {
    try {
        let res = await api.post(arg);
        return res.data;
    } catch (error) {
        console.error("POST controlado ==>", error);
        return null;
    }
   
};

export {get, post};
