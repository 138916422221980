import React, {useEffect, useContext, useState, useLayoutEffect} from "react";
import {Layout} from "antd";
import Navegacion from "../general/Navegacion";
import TabTask from "../general/TabTask";
import {AppRouter} from "../AppRouter";
import {ERPMasterContext} from "../contexts/ERPMasterAuth";
import api from "../servicios/api";
import {post} from "../servicios/api-m";
import {metodosDexie} from "../servicios/IndexedDb-dexie";
import Menu from "../general/Menu";
const {Header, Content, Sider} = Layout;

const LayoutApp = () => {
    const data = useContext(ERPMasterContext);

    useLayoutEffect(() => {
        api.defaults.headers.common["id_compania"] = data.id_compania;
        api.defaults.headers.common["id_usuario"] = data.id_usuario;
        api.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        console.warn("Seteando Autenticación =>", data);
    }, [data]);

    useLayoutEffect(() => {
        const cargaInicial = async () => {
            let data = await post("/api/General/init");
            await metodosDexie.addMonedas(data.lista_monedas);
            await metodosDexie.addMonedaBase(data.id_moneda_base);
        };

        cargaInicial();

        console.warn("<<<<<<= CARGA PROVIDER DATOS INICIALES =>>>>>>");
    }, []);

    if (data.despacho) {
        return (
            <Layout style={{height: "100vh"}}>
                <Menu />
                <AppRouter />
            </Layout>
        );
    }

    return (
        <Layout style={{height: "100vh"}}>
            <Header className="header">
                <div className="logo" />
            </Header>
            <Layout>
                <Sider>
                    <Navegacion />
                </Sider>
                <Layout>
                    <TabTask />
                    <Content className="site-layout-background" style={{overflow: "scroll"}}>
                        <AppRouter />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default LayoutApp;
