import React, {useState, useEffect} from "react";
import {Input, InputType} from "@ui5/webcomponents-react";

function check(e, value) {
    console.info(e);
    console.info(value);
    //Check Charater
    var unicode = e.charCode ? e.charCode : e.keyCode;
    if (value.indexOf(".") != -1) if (unicode == 46) return false;
    if (unicode != 8) if ((unicode < 48 || unicode > 57) && unicode != 46) return false;
}

const InputMonto = ({value, onChange, simbolo}) => {
    const [valueInput, setvalueInput] = useState(null);

    const IconSimbolo = (props) => {
        return <div slot={props.slot}>{simbolo}</div>;
    };

    useEffect(() => {
      setvalueInput(value)
    }, [value])
    

    const OnKeuUp = (evt) => {
        evt.target.value = evt.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
        if (evt.which !== 110) {
            var n = parseFloat(evt.target.value.replace(/\,/g, ""));
            setvalueInput(n.toLocaleString("en"));
        }
    };

    return (
        <Input
            value={valueInput}
            type={InputType.Text}
            onBlur={() => onChange(parseFloat(valueInput.replace(/\,/g, "")))}
            onKeyUp={OnKeuUp}
            icon={<IconSimbolo />}
        />
    );
};

export default InputMonto;
