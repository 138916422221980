import React, {useState} from "react";
import {Modal, Button} from "antd";
import { Modals } from '@ui5/webcomponents-react';


const ModalContainer = ({children, stateVisible, fStateVisible }) => {


    return (
        <Modal title={null} visible={stateVisible} footer={null} closable={true} onCancel={() => fStateVisible(false)}>
            {children}
        </Modal>
    );
};

export default ModalContainer;
