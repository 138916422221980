import React, { useContext, useRef } from "react";
import { ERPMasterContext } from "../contexts/ERPMasterAuth";
import SAPui from "../components/refSAPui";
import GridDocumentosRAPIFE from "./components/GridDocumentosRAPIFE";

const FormAsistenteImportacionCompra = () => {
  const contextERP = useContext(ERPMasterContext);

  const step2 = useRef();
  const step3 = useRef();

  const goToStep2 = () => {
    step2.current.selected = true;
    step2.current.disabled = false;
  };

  const goToStep3 = () => {
    step3.current.selected = true;
    step3.current.disabled = false;
  };

  const handleStep2Completed = () => {
    document.querySelector("#goToStep3").removeAttribute("hidden");
  };

  const finalizeWizard = () => {
    alert("Wizard is now completed!");
  };

  const handlePriceInput = () => {
    document.querySelector("#finalizeBtn").removeAttribute("hidden");
  };

  return (
    <>
      <SAPui.Wizard>
        <SAPui.WizardStep icon="product" titleText="Seleccionar" selected>
          <SAPui.Title>1. Seleccione la factura a importar</SAPui.Title>
          <SAPui.FilterBar
            showFilterConfiguration
            considerGroupName
            showSearchOnFiltersDialog
          >
            <SAPui.FilterGroupItem label="Tipos de documentos">
              <SAPui.Select placeholder="Placeholder">
                <SAPui.Option>Pendientes</SAPui.Option>
                <SAPui.Option>Procesados</SAPui.Option>
              </SAPui.Select>
            </SAPui.FilterGroupItem>
          </SAPui.FilterBar>

          <GridDocumentosRAPIFE />
          
        </SAPui.WizardStep>
        <SAPui.WizardStep
          icon="hint"
          titleText="Verificación"
          disabled
          ref={step2}
        >
          <SAPui.Title>2. Verificación de artículos</SAPui.Title>

          <SAPui.Button
            id="goToStep3"
            design={SAPui.ButtonDesign.Emphasized}
            onClick={goToStep3}
            hidden
          >
            Continuar
          </SAPui.Button>
        </SAPui.WizardStep>
        <SAPui.WizardStep icon="lead" titleText="Pricing" disabled ref={step3}>
          <SAPui.Title>4. Pricing</SAPui.Title>
          <SAPui.Label wrappingType={SAPui.WrappingType.None}>
            Integer pellentesque leo sit amet dui vehicula, quis ullamcorper est
            pulvinar. Nam in libero sem. Suspendisse arcu metus, molestie a
            turpis a, molestie aliquet dui. Donec ppellentesque leo sit amet dui
            vehicula, quis ullamcorper est pulvinar. Nam in libero sem.
            Suspendisse arcu metus, molestie a turpis a, molestie aliquet dui.
            Donec pulvinar, sapien corper eu, posuere malesuada nisl. Integer
            pellentesque leo sit amet dui vehicula, quis ullamcorper est
            pulvinar. Nam in libero sem. Suspendisse arcu metus, molestie a
            turpis a, molestie aliquet dui. Donec pulvinar, sapien
          </SAPui.Label>
          <SAPui.Input placeholder="Item Price" onInput={handlePriceInput} />
          <br />
          <SAPui.Button
            design={SAPui.ButtonDesign.Emphasized}
            onClick={finalizeWizard}
            hidden
            id="finalizeBtn"
          >
            Finalize
          </SAPui.Button>
        </SAPui.WizardStep>
      </SAPui.Wizard>
    </>
  );
};

export default FormAsistenteImportacionCompra;
