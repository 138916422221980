import React, {useState } from "react";
import {
  Table,
  TableColumn,
  TableCell,
  TableRow,
  Label
} from "@ui5/webcomponents-react";

const SerieLoteDetalle = ({ modulo, idDocumento, modoEditar}) => {
  const [Detalle, setDetalle] = useState([])

  return (
    <Table
      columns={
        <>
          <TableColumn>
            <Label>Código</Label>
          </TableColumn>
          <TableColumn>
            <Label>Artículo</Label>
          </TableColumn>
          <TableColumn>
            <Label>Cantidad</Label>
          </TableColumn>
          <TableColumn>
            <Label>Serie</Label>
          </TableColumn>
          <TableColumn>
            <Label>Lote</Label>
          </TableColumn>
          <TableColumn>
            <Label>Fecha vence lote</Label>
          </TableColumn>
          <TableColumn>
            <Label>Comentario</Label>
          </TableColumn>
          <TableColumn>
            <Label></Label>
          </TableColumn>
        </>
      }
    >
      {Detalle?.map((item) => (
        <TableRow key={item.index}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default SerieLoteDetalle;
