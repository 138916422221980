import React, {useState} from "react";
import { ProductSwitch, ProductSwitchItem } from "@ui5/webcomponents-react";
import { useNavigate } from "react-router-dom";

const IndexDespacho = () => {
  const navigate = useNavigate();
  const [mostrar, setmostrar] = useState(false)
  return (
    <>
      <ProductSwitch>

        <ProductSwitchItem
          titleText="Cargas"
          subtitleText="Documentos de carga"
          icon="list"
          onClick={() => navigate("/despacho/cargas")}
        />

        <ProductSwitchItem
          titleText="Preparación"
          subtitleText="Preparación de facturas para carga"
          icon="contacts"
          onClick={() => navigate("/despacho/preparacion")}
        />

        <ProductSwitchItem
          titleText="Verificación"
          subtitleText="Comprobación de bultos "
          icon="business-objects-experience"
          onClick={() => navigate("/despacho/verificacion")}
        />
        
      </ProductSwitch>

    

    </>
  );
};

export default IndexDespacho;
