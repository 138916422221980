import React from "react";
import {
  FilterBar,
  FilterGroupItem,
  ButtonDesign,
  Title,
  Input,
  AnalyticalTable,
} from "@ui5/webcomponents-react";

const CXCEmitidas = () => {
  const data = [
    {
      departamento: "Peter Franco",
      documento: 22,
      fecha: "05/05/2022",
      cliente: "Nombre de cliente",
      total: 15555,
    },
    {
      departamento: "Peter Franco",
      documento: 22,
      fecha: "05/05/2022",
      cliente: "Nombre de cliente",
      total: 15555,
    },
  ];

  const columns = [
    {
      Header: "Departamento",
      accessor: "departamento",
    },
    {
      Header: "Documento",
      accessor: "documento",
    },
    {
      Header: "Fecha",
      accessor: "fecha",
    },
    {
        Header: "Fecha de vencimiento",
        accessor: "fecha_vencimiento",
      },
    {
      Header: "Cliente",
      accessor: "cliente",
    },
    {
      Header: "Total",
      accessor: "total",
    },
    {
        Header: "Saldo",
        accessor: "saldo",
      },
  ];

  return (
    <>
      <FilterBar
        showFilterConfiguration
        considerGroupName
        showSearchOnFiltersDialog
      >
        <FilterGroupItem label="Cliente">
          <Input placeholder="Placeholder" />
        </FilterGroupItem>
        <FilterGroupItem label="Filter 2" groupName="Not customizable">
          <Input placeholder="Placeholder" />
        </FilterGroupItem>
      </FilterBar>

      <AnalyticalTable
        data={data}
        columns={columns}
        selectionMode="SingleSelect"
      />
    </>
  );
};

export default CXCEmitidas;
