import React, {useContext} from "react";
// import {TabContainer, Tab} from "@ui5/webcomponents-react";
import { Tabs } from 'antd';

import {GeneralContext} from "../contexts/GeneralContext";
import {useNavigate} from "react-router-dom";

const { TabPane } = Tabs;

const TabTask = () => {

  const nav = useNavigate();

  const {misTareas, tareaActual, setTareaActual} = useContext(GeneralContext);

  const OnTabClick = (key) => {
    let keyd = key.split("|");
    let tarea = misTareas.filter(r => r.id === keyd[0] && r.sesion === Number(keyd[1]) )[0];
    nav(tarea.route);
    setTareaActual(key);
  };

  return (
    <Tabs style={{marginLeft:"15px"}} onChange={OnTabClick} defaultActiveKey={tareaActual} activeKey={tareaActual}>
      {misTareas.map((item) => (
        <TabPane          
          key={item.id + "|" + item.sesion}
          tab={item.title}             
        />
      ))}
    </Tabs>
  );
};

export default TabTask;
